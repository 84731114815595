@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./Assests/fonts/font.css";

/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

* {
    font-family: "Poppins", sans-serif;
}

@layer base {
    body {
        @apply text-[#1f2937];
    }
}
html {
    font-family:
        Proxima Nova,
        system-ui,
        sans-serif;
}

html {
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
}

input,
select {
    outline: none;
}
.batch_heart {
    background: tomato;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: absolute;
    right: -24%;
    top: -9%;
    cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.glass {
    /* From https://css.glass */
    background: rgba(107, 107, 107, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.6px);
    -webkit-backdrop-filter: blur(2.6px);
    border: 1px solid #ffffff70;
}

.glass-secondary {
    background: rgba(206, 204, 204, 0.23);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    border: 1px solid rgba(255, 255, 255, 0.44);
}

.nice-scroll-bar::-webkit-scrollbar-thumb {
    background-color: #fbbf24;
    border-radius: 5px;
}
.nice-scroll-bar::-webkit-scrollbar {
    width: 5px;
}

.no-scroll::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* CountDown.css */
.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-interval {
  font-size: 25px;
  color: #475ad2;
  margin: 0 5px;
  animation: dropDown 1s ease-in-out, fadeIn 1s ease-in-out;
}

.days {
  animation-delay: 0.2s; /* Delay for days */
}

.hours {
  animation-delay: 0.4s; /* Delay for hours */
}

.minutes {
  animation-delay: 0.6s; /* Delay for minutes */
}

.seconds {
  animation-delay: 0.8s; /* Delay for seconds */
}

@keyframes dropDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
